import { ISupplier_Type } from '@bridebook/models/source/models/Suppliers.types';
import { PremiumTiers } from '@bridebook/toolbox';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { countriesWithLostLeadModal } from '@bridebook/toolbox/src/i18n/features';
import { RequiredTiersManager } from 'lib/premium/required-tiers-manager';
import { ISupplierInterestConfirmationStorage } from 'lib/storage-manager/basic/supplier-interest-confirmation';

interface IProps {
  storage: ISupplierInterestConfirmationStorage | null;
  countryCode: CountryCodes;
  supplierId: string | undefined;
  supplierTier: PremiumTiers | undefined;
  supplierType: ISupplier_Type | undefined;
}

export const isLostLeadModalAvailable = ({
  storage,
  countryCode,
  supplierId,
  supplierTier,
  supplierType,
}: IProps) => {
  const hasNotBeenShownYet = supplierId && !storage?.suppliersShown?.[supplierId];

  return (
    hasNotBeenShownYet &&
    (supplierTier ?? PremiumTiers.Unverified) >= RequiredTiersManager.lostLead(supplierType) &&
    countriesWithLostLeadModal.includes(countryCode)
  );
};
