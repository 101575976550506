import { UserScopedVersionableStorageManager } from '@bridebook/toolbox/src/storage-managers/managers/user-scoped-versionable-storage-manager';

export interface ISupplierInterestConfirmationStorage {
  suppliersShown?: Record<string, boolean>;
  lastShown?: string;
  count?: number;
}

/**
 * This storage is used for keeping track of interest confirmation popups shown to the user. This
 * includes lost leads presented as a slide in VenueConfirmationModal, LostLead modal shown as a
 * consequence of Not interested quick response click in Inbox and unfavouriting a supplier.
 *
 * @param suppliersShown - Object with supplier ids as keys and boolean values indicating if the
 * supplier interest confirmation or lost lead modal was shown.
 * @param lastShown - The last time the interest confirmation was shown.
 * @param count - The total number of lost lead modal shown, but only for unfavouriting flow.
 */
export const SupplierInterestConfirmationStorage =
  UserScopedVersionableStorageManager<ISupplierInterestConfirmationStorage>({
    storageKey: 'supplier-interest-confirmation',
    version: '1.0',
  });
